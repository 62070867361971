<template>
  <QuotaVisitMaster />
</template>

<script>
export default {
  name: "QuotaVisitMasterPage",
  components: {
    QuotaVisitMaster: () => import("~cp/Quota/QuotaPreview/QuotaVisitMaster"),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.getters["quota/isQuotaValidated"]) {
        next();
      } else {
        next({
          name: "QuotaSum",
          params: { quotaId: vm.$route.params.quotaId },
        });
      }
    });
  },
};
</script>

<style></style>
